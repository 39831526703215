import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import { trace } from '@opentelemetry/api';
import { useShowcase } from '@magalu/mixer-graphql';
import { useCookies } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ShowcaseItem from './ShowcaseItem';
var Showcase = function Showcase(_ref) {
  var _publicRuntimeConfig$;
  var structure = _ref.structure,
    staticProps = _ref["static"],
    config = _ref.config;
  var span = trace.getActiveSpan();
  var pageId = staticProps.page,
    placeId = staticProps.placement,
    productId = staticProps.productId,
    carouselConfig = staticProps.carouselConfig,
    disableSidebar = staticProps.disableSidebar,
    _staticProps$jsonLD = staticProps.jsonLD,
    jsonLD = _staticProps$jsonLD === void 0 ? true : _staticProps$jsonLD,
    _staticProps$titleAs = staticProps.titleAs,
    titleAs = _staticProps$titleAs === void 0 ? 'h2' : _staticProps$titleAs,
    titleSize = staticProps.titleSize,
    _staticProps$productT = staticProps.productTitleAs,
    productTitleAs = _staticProps$productT === void 0 ? 'h3' : _staticProps$productT,
    _staticProps$spa = staticProps.spa,
    spa = _staticProps$spa === void 0 ? false : _staticProps$spa,
    showFullListBtn = staticProps.showFullListBtn,
    categories = staticProps.categories,
    subCategories = staticProps.subCategories,
    sellers = staticProps.sellers,
    _staticProps$layoutCh = staticProps.layoutChildren,
    layoutChildren = _staticProps$layoutCh === void 0 ? {} : _staticProps$layoutCh,
    customType = staticProps.type,
    _staticProps$showDisc = staticProps.showDiscount,
    showDiscount = _staticProps$showDisc === void 0 ? false : _staticProps$showDisc,
    _staticProps$showDisc2 = staticProps.showDiscountOnlyCashInfo,
    showDiscountOnlyCashInfo = _staticProps$showDisc2 === void 0 ? false : _staticProps$showDisc2,
    _staticProps$showInCa = staticProps.showInCashLabel,
    showInCashLabel = _staticProps$showInCa === void 0 ? false : _staticProps$showInCa,
    inCashDescription = staticProps.inCashDescription,
    slidesPerViewBetterOffer = staticProps.slidesPerViewBetterOffer,
    showButton = staticProps.showButton;
  var _structure$config = structure.config;
  _structure$config = _structure$config === void 0 ? {} : _structure$config;
  var cartUrl = _structure$config.cartUrl;
  var _ref2 = structure.route || {},
    storeId = _ref2.storeId;
  var _useCookies = useCookies(),
    partnerId = _useCookies.partnerId,
    cartId = _useCookies.cartId,
    location = _useCookies.location,
    _useCookies$customerI = _useCookies.customerId,
    customerId = _useCookies$customerI === void 0 ? '0' : _useCookies$customerI,
    trackId = _useCookies.trackId;
  var publicRuntimeConfig = config.publicRuntimeConfig;
  var timeout = publicRuntimeConfig == null ? void 0 : (_publicRuntimeConfig$ = publicRuntimeConfig.recommendation) == null ? void 0 : _publicRuntimeConfig$.timeout;
  var _useShowcase = useShowcase({
      categories: categories,
      customerId: customerId,
      includePagination: showFullListBtn,
      options: {
        timeout: timeout
      },
      pageId: pageId,
      partnerId: partnerId,
      placeId: placeId,
      productId: productId,
      sellers: sellers,
      storeId: storeId,
      subCategories: subCategories,
      trackId: trackId,
      zipCode: location == null ? void 0 : location.zipCode
    }),
    recommendation = _useShowcase.recommendation;
  if (!(recommendation == null ? void 0 : recommendation.length) || (recommendation == null ? void 0 : recommendation.every(function (item) {
    var _item$products;
    return Boolean(item == null ? void 0 : (_item$products = item.products) == null ? void 0 : _item$products.length);
  }))) {
    span == null ? void 0 : span.addEvent('mixer.showcase.empty', {
      'log.message': "{ customerId: ".concat(customerId, ", pageId: ").concat(pageId, ", partnerId: ").concat(partnerId, ", placement: ").concat(placeId, ", trackId: ").concat(trackId, "}"),
      'log.severity': 'info'
    });
  }
  var designTokenMap = {
    'showcase.betteroffer': 'betteroffer',
    'showcase.button': 'button',
    'showcase.carousel': 'carousel',
    'showcase.endless': 'list',
    'showcase.highlight': 'highlight',
    'showcase.list': 'list',
    'showcase.rank': 'rank'
  };
  var getDesignTokenId = function getDesignTokenId(key) {
    var _designTokenMap$key;
    return (_designTokenMap$key = designTokenMap[key]) !== null && _designTokenMap$key !== void 0 ? _designTokenMap$key : 'list';
  };
  return !!(recommendation == null ? void 0 : recommendation.length) && recommendation.map(function (el, index) {
    return React.createElement(ShowcaseItem, _extends({
      carouselConfig: carouselConfig,
      key: "showcase-".concat(pageId, "-").concat(placeId, "-").concat(index + 1),
      disableSidebar: disableSidebar,
      position: index + 1,
      pageId: pageId,
      placeId: placeId,
      jsonLD: jsonLD,
      titleAs: titleAs,
      titleSize: titleSize,
      productTitleAs: productTitleAs,
      layout: layoutChildren,
      cartInfo: {
        cartId: cartId,
        cartUrl: cartUrl,
        location: location,
        partnerId: partnerId
      },
      customerId: customerId,
      productId: productId,
      categories: categories,
      subCategories: subCategories,
      slidesPerViewBetterOffer: slidesPerViewBetterOffer,
      showButton: showButton
    }, el, {
      type: customType || getDesignTokenId(el == null ? void 0 : el.designTokenId),
      spa: spa,
      structure: structure,
      showInCashInfo: showInCashLabel,
      showDiscount: showDiscount,
      showDiscountOnlyCashInfo: showDiscountOnlyCashInfo,
      inCashDescription: inCashDescription
    }));
  });
};
Showcase.defaultProps = {
  "static": {}
};
process.env.NODE_ENV !== "production" ? Showcase.propTypes = {
  "static": shape({})
} : void 0;
Showcase.ssr = false;
Showcase.displayName = 'Showcase';
Showcase.dataSource = {
  showcase: {
    query: 'ShowcaseQuery'
  }
};
export default withLayoutProps(Showcase);