import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import Accordion from '@magalu/stereo-ui/atoms/Accordion';
export var AccordionStyled = styled(Accordion).attrs(function (props) {
  return _extends({
    bg: 'background.white',
    padding: '12px !important'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));