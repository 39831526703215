import React from 'react';
import Head from 'next/head';
import { arrayOf, shape, string } from 'prop-types';
var isDenied = function isDenied(term, deniedTerms) {
  return deniedTerms.indexOf(term) >= 0;
};
var retrieveDeniedContent = function retrieveDeniedContent(terms, deniedTerms, content) {
  return isDenied(terms, deniedTerms) ? content : null;
};
var SearchTitle = function SearchTitle(_ref) {
  var _data$seoSearchConten, _data$seoSearchConten2;
  var structure = _ref.structure,
    staticProps = _ref["static"],
    data = _ref.data;
  var capitalizeWords = function capitalizeWords(str) {
    return str == null ? void 0 : str.split(' ').map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  };
  var _structure$route = structure == null ? void 0 : structure.route,
    term = _structure$route.term;
  var defaultTitle = staticProps.defaultTitle,
    defaultDescription = staticProps.defaultDescription,
    _staticProps$deniedTe = staticProps.deniedTerms,
    deniedTerms = _staticProps$deniedTe === void 0 ? [] : _staticProps$deniedTe,
    deniedDescription = staticProps.deniedDescription,
    allowedDescription = staticProps.allowedDescription;
  var terms = capitalizeWords(term);
  var deniedTitle = "".concat(terms, " | Resultado de Busca Interna | Magazine Luiza");
  var allowedTitle = (data == null ? void 0 : (_data$seoSearchConten = data.seoSearchContent) == null ? void 0 : (_data$seoSearchConten2 = _data$seoSearchConten.meta) == null ? void 0 : _data$seoSearchConten2.title) || "".concat(terms, " em Promo\xE7\xE3o no Magazine Luiza");
  var title = !terms ? defaultTitle : retrieveDeniedContent(term, deniedTerms, deniedTitle) || allowedTitle || defaultTitle;
  var description = !terms ? defaultDescription : retrieveDeniedContent(term, deniedTerms, deniedDescription) || allowedDescription || defaultDescription;
  return React.createElement(Head, null, React.createElement("title", {
    "data-testid": "meta-title"
  }, title), React.createElement("meta", {
    "data-testid": "meta-description",
    name: "description",
    content: description
  }));
};
SearchTitle.displayName = 'SearchTitle';
SearchTitle.defaultProps = {
  data: {},
  "static": {
    allowedDescription: '',
    allowedTitle: '',
    defaultDescription: '',
    defaultTitle: '',
    deniedDescription: '',
    deniedTerms: [],
    deniedTitle: ''
  },
  structure: {
    route: {}
  }
};
process.env.NODE_ENV !== "production" ? SearchTitle.propTypes = {
  data: shape({}),
  "static": shape({
    allowedDescription: string,
    allowedTitle: string,
    defaultDescription: string,
    defaultTitle: string,
    deniedDescription: string,
    deniedTerms: arrayOf(string),
    deniedTitle: string
  }),
  structure: shape({
    route: shape({})
  })
} : void 0;
SearchTitle.ssr = true;
export default SearchTitle;