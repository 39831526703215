import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import React, { useEffect, useRef, useState } from 'react';
import { shape, arrayOf } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import { filtersParser, scrollToRef, hasSomeEqualProp, routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import FiltersUnique from './FilterUnique';
import FiltersMultiple from './FiltersMultiple';
import FilterHierarchical from './FilterHierarchical';
import FilterPrice from './FilterPrice';
import { AccordionStyled } from './SearchFilters.styles';
var SearchFilters = function SearchFilters(_ref) {
  var _data$search2, _structure$route;
  var data = _ref.data,
    structure = _ref.structure,
    _ref$static = _ref["static"],
    _ref$static$customCat = _ref$static.customCategoryFilter,
    customCategoryFilter = _ref$static$customCat === void 0 ? false : _ref$static$customCat,
    _ref$static$searchTer = _ref$static.searchTerm,
    searchTerm = _ref$static$searchTer === void 0 ? '' : _ref$static$searchTer,
    _ref$static$displayOp = _ref$static.displayOpened,
    displayOpened = _ref$static$displayOp === void 0 ? 8 : _ref$static$displayOp,
    _ref$static$displaySe = _ref$static.displaySearch,
    displaySearch = _ref$static$displaySe === void 0 ? [] : _ref$static$displaySe,
    _ref$static$removeFil = _ref$static.removeFilters,
    removeFilters = _ref$static$removeFil === void 0 ? [] : _ref$static$removeFil,
    uniqueFiltersConfig = _ref$static.uniqueFilters;
  var refs = useRef({});
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var query = structure.query;
  var sortFilters = function sortFilters() {
    var _data$search;
    var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var filterBySlug = filters.reduce(function (acc, filter) {
      return _extends({}, acc, _defineProperty({}, filter.slug, filter));
    }, {});
    var baseFilters = (data == null ? void 0 : (_data$search = data.search) == null ? void 0 : _data$search.filters) || [];
    return baseFilters.reduce(function (acc, _ref2) {
      var _filterBySlug$slug, _filterBySlug$slug$va, _filterBySlug$slug2;
      var slug = _ref2.slug;
      return filterBySlug[slug] && (((_filterBySlug$slug = filterBySlug[slug]) == null ? void 0 : (_filterBySlug$slug$va = _filterBySlug$slug.values) == null ? void 0 : _filterBySlug$slug$va.length) || ((_filterBySlug$slug2 = filterBySlug[slug]) == null ? void 0 : _filterBySlug$slug2.componentType) === 'priceRange') ? [].concat(_toConsumableArray(acc), [filterBySlug[slug]]) : acc;
    }, []);
  };
  var buildFilterFilters = function buildFilterFilters() {
    var filters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var _filtersParser = filtersParser({
        customCategoryFilter: customCategoryFilter,
        data: data,
        filters: filters,
        query: query,
        removeFilters: removeFilters,
        structure: structure
      }),
      attributeFilters = _filtersParser.attributeFilters,
      reviewFilters = _filtersParser.reviewFilters,
      categoryFilters = _filtersParser.categoryFilters,
      priceFilters = _filtersParser.priceFilters;
    var newParsedFilters = attributeFilters.map(function (filter) {
      var componentType = uniqueFiltersConfig.some(function (uniqueFilterConfig) {
        return hasSomeEqualProp(filter, uniqueFilterConfig);
      }) ? 'unique' : 'multiple';
      return _extends({}, filter, {
        componentType: componentType
      });
    });
    if (reviewFilters) {
      reviewFilters.componentType = 'unique';
      newParsedFilters.push(reviewFilters);
    }
    if (categoryFilters) {
      categoryFilters.componentType = 'hierarchical';
      newParsedFilters.push(categoryFilters);
    }
    if (priceFilters) {
      priceFilters.componentType = 'priceRange';
      newParsedFilters.push(priceFilters);
    }
    return sortFilters(newParsedFilters);
  };
  var toggleDisplayOpened = function toggleDisplayOpened(currentFilters) {
    return currentFilters.slice(0, displayOpened).reduce(function (acc, filter) {
      return _extends({}, acc, _defineProperty({}, filter.slug, true));
    }, {});
  };
  var parsedFilters = buildFilterFilters(data == null ? void 0 : (_data$search2 = data.search) == null ? void 0 : _data$search2.filters);
  var _useState = useState(toggleDisplayOpened(parsedFilters)),
    _useState2 = _slicedToArray(_useState, 2),
    collapse = _useState2[0],
    collapsableSet = _useState2[1];
  var _useState3 = useState(parsedFilters),
    _useState4 = _slicedToArray(_useState3, 2),
    allFilters = _useState4[0],
    allFiltersSet = _useState4[1];
  var toggleCollapse = function toggleCollapse(slug) {
    var action = (collapse == null ? void 0 : collapse[slug]) ? 'close' : 'open';
    publish("productlist:filter:".concat(action));
    collapsableSet(function (prev) {
      return _extends({}, prev, _defineProperty({}, slug, !(prev == null ? void 0 : prev[slug])));
    });
  };
  var updateFilters = function updateFilters() {
    var _data$search3;
    var updatedFilters = buildFilterFilters(data == null ? void 0 : (_data$search3 = data.search) == null ? void 0 : _data$search3.filters);
    allFiltersSet(updatedFilters);
  };
  useEffect(function () {
    updateFilters();
  }, [JSON.stringify(data)]);
  useEffect(function () {
    collapsableSet(toggleDisplayOpened(parsedFilters));
  }, [structure == null ? void 0 : (_structure$route = structure.route) == null ? void 0 : _structure$route.term]);
  var onFilterItemClick = function onFilterItemClick(e, filter) {
    e.preventDefault();
    routePush({
      path: filter == null ? void 0 : filter.href,
      spa: true
    });
  };
  var onFilterPriceApply = function onFilterPriceApply(e, _ref3, filter) {
    var min = _ref3.min,
      max = _ref3.max;
    e.preventDefault();
    routePush({
      path: filter == null ? void 0 : filter.buildHref(max, min),
      spa: true
    });
  };
  var renderMultiple = function renderMultiple(_ref4) {
    var type = _ref4.type,
      values = _ref4.values;
    return React.createElement(FiltersMultiple, {
      values: values,
      searchTerm: searchTerm,
      displaySearch: displaySearch == null ? void 0 : displaySearch.includes(type),
      onClickCollapse: function onClickCollapse() {
        return scrollToRef({
          current: refs.current[type]
        });
      },
      onClick: onFilterItemClick
    });
  };
  var renderUnique = function renderUnique(_ref5) {
    var type = _ref5.type,
      values = _ref5.values;
    return React.createElement(FiltersUnique, {
      values: values,
      type: type,
      searchTerm: searchTerm,
      onClick: onFilterItemClick
    });
  };
  var renderHierarchical = function renderHierarchical(_ref6) {
    var type = _ref6.type,
      values = _ref6.values;
    return React.createElement(FilterHierarchical, {
      values: values,
      type: type,
      searchTerm: searchTerm,
      onClick: onFilterItemClick,
      onClickCollapse: function onClickCollapse() {
        return scrollToRef({
          current: refs.current[type]
        });
      }
    });
  };
  var renderPrice = function renderPrice(filterProps) {
    return React.createElement(FilterPrice, _extends({}, filterProps, {
      searchTerm: searchTerm,
      onApply: onFilterPriceApply
    }));
  };
  var renderByType = {
    hierarchical: renderHierarchical,
    multiple: renderMultiple,
    priceRange: renderPrice,
    unique: renderUnique
  };
  return React.createElement(Box, {
    "data-testid": "search-filters"
  }, allFilters == null ? void 0 : allFilters.map(function (filterItem) {
    var componentType = filterItem.componentType,
      type = filterItem.type,
      slug = filterItem.slug,
      label = filterItem.label;
    return React.createElement(React.Fragment, null, React.createElement(AccordionStyled, {
      ref: function ref(element) {
        refs.current["".concat(type, "-").concat(slug)] = element;
      },
      asTitle: "p",
      key: "".concat(type, "-").concat(slug),
      title: label,
      open: collapse == null ? void 0 : collapse[slug],
      onClick: function onClick() {
        return toggleCollapse(slug);
      },
      openIcon: "ChevronDown",
      closeIcon: "ChevronUp",
      mb: 2,
      mt: 2,
      borderRadius: 10,
      "data-testid": "accordion-".concat(componentType, "-filters")
    }, renderByType[componentType](filterItem)));
  }));
};
process.env.NODE_ENV !== "production" ? SearchFilters.propTypes = {
  data: shape({
    search: shape({
      filters: arrayOf(shape({}))
    })
  }),
  "static": shape({}),
  structure: shape({
    route: shape({})
  })
} : void 0;
SearchFilters.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
SearchFilters.ssr = true;
export default withLayoutProps(SearchFilters);